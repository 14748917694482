import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@shared/constants/constants';

@Directive({
    selector: '[platformDependantRouterLink]',
    standalone: false
})
export class PlatformDependantRouterLinkDirective {
  @Input() largeScreenPaths: string[] = [];
  @Input() smallScreenPaths: string[] = [];
  @Input() breakpoint: number = Constants.DEFAULT_BREAKPOINT;

  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const screenWidth = window.innerWidth;
    const selectedPaths = screenWidth >= this.breakpoint ? this.largeScreenPaths : this.smallScreenPaths;
    if (selectedPaths.length > 0) {
      this.router.navigate([...selectedPaths]);
    }
  }
}
