/// <reference types="@types/google.maps" />

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import PlaceResult = google.maps.places.PlaceResult;
import Autocomplete = google.maps.places.Autocomplete;
import MapsEventListener = google.maps.MapsEventListener;
import { Options } from '@shared/models/autocomplete-options';

@Directive({
    selector: '[stiiltGooglePlacesAutocomplete]',
    standalone: false
})
export class GooglePlacesAutocompleteDirective implements AfterViewInit {
  @Input() public options!: Options;
  @Output() public addressChanged: EventEmitter<PlaceResult> = new EventEmitter();
  public place!: PlaceResult;
  private autocomplete!: Autocomplete;
  private eventListener!: MapsEventListener;

  constructor(
    private el: ElementRef,
    private ngZone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    if (!this.options) this.options = new Options();
    this.initialize();
  }

  public reset(): void {
    this.autocomplete.setComponentRestrictions(this.options.componentRestrictions);
    this.autocomplete.setTypes(this.options.types);
  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private initialize(): void {
    if (!this.isGoogleLibExists()) throw new Error('Google maps library can not be found');

    this.autocomplete = new Autocomplete(this.el.nativeElement, this.options);

    if (!this.autocomplete) throw new Error('Autocomplete is not initialized');

    if (!this.autocomplete.addListener !== null) {
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
        this.handleChangeEvent();
      });
    }

    this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
      if (!event.key) {
        return;
      }
      const key = event.key.toLowerCase();

      if (key === 'enter' && event.target === this.el.nativeElement) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();

      if (this.place) {
        this.addressChanged.emit(this.place);
      }
    });
  }
}
