import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Directive({
    selector: '[stiiltCustomRouterLinkActive]',
    standalone: false
})
export class CustomRouterLinkActiveDirective implements OnInit {
  @Input() largeScreenPaths!: string | string[];
  @Input() activeClassName!: string;

  constructor(
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.updateClass(this.router.url);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.updateClass(this.router.url);
    });
  }

  private updateClass(currentUrl: string) {
    const paths = Array.isArray(this.largeScreenPaths)
      ? this.largeScreenPaths.join('/')
      : (this.largeScreenPaths as string);
    if (currentUrl.includes(paths)) {
      this.renderer.addClass(this.el.nativeElement, this.activeClassName);
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.activeClassName);
    }
  }
}
