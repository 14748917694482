import { CommonModule, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoPipe } from '@jsverse/transloco';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';
import { ToastModule } from 'primeng/toast';
import { CursorPointerClickDirective } from './directives/cursor-pointer-click.directive';
import { CustomRouterLinkActiveDirective } from './directives/custom-router-link-active.directive';
import { PlatformDependantRouterLinkDirective } from './directives/platform-dependant-router-link.directive';
import { StiiltTemplateDirective } from './directives/stiilt-template.directive';
import { NotSpecifiedPipe } from './pipes/not-specified.pipe';
import { PrimengModule } from './primeng.module';
import { GooglePlacesAutocompleteDirective } from '@shared/components/google-places-autocomplete/google-places-autocomplete.directive';
import { SameWidthColumnDirective } from '@shared/directives/same-width-column.directive';

const sharedModule = [
  TranslocoModule,
  TranslocoPipe,
  TranslocoDatePipe,
  CommonModule,
  PrimengModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NgOptimizedImage,
  ToastModule,
  NgTemplateOutlet,
];

const pipesAndDirectives = [
  PlatformDependantRouterLinkDirective,
  CustomRouterLinkActiveDirective,
  CursorPointerClickDirective,
  GooglePlacesAutocompleteDirective,
];

const standalonePipesAndDirectives = [NotSpecifiedPipe, StiiltTemplateDirective, SameWidthColumnDirective];

@NgModule({
  declarations: [...pipesAndDirectives],
  imports: [...sharedModule, ...standalonePipesAndDirectives],
  exports: [...sharedModule, ...pipesAndDirectives, ...standalonePipesAndDirectives],
})
export class SharedModule {}
